import React from 'react'
import {graphql} from 'gatsby'
import {css} from '@emotion/core'
import Img from 'gatsby-image'
import styled from '@emotion/styled'
import Layout from 'components/layout'
import Container from 'components/container'
import SEO from 'components/seo'
import theme from '../../config/theme'
import portfolioItems from 'data/portfolioItems'
import {bpMaxSM} from 'lib/breakpoints'
import Hero from 'components/big-hero'
import slugify from '@sindresorhus/slugify'
import Markdown from 'react-markdown'
import HeaderImage from '../images/portfolio/header.svg'
import ShareCard from '../images/portfolio/share-card@2x.jpg'

export default function Courses({data}) {
  function courseImageByTitle(title) {
    switch (title) {
      case `VenueX Mobile App`:
        return (
          <Img fluid={data.VenueXMobileApp.childImageSharp.fluid} />
        )
      case `Pattaya Nightlife Guide`:
        return (
          <Img fluid={data.pattayaNightlifeGuide.childImageSharp.fluid} />
        )
      case `MMDP`:
        return (
          <Img fluid={data.mmdp.childImageSharp.fluid} />
        )
      default:
        return null
    }
  }

  return (
    <Layout
      headerColor={theme.colors.white}
      backgroundColor="#F6F5F9"
      hero={
        <Hero
          title="James Work"
          text="A display of some of the projects I've gotten opportunity to work on"
          image={HeaderImage}
          background="linear-gradient(213deg, #5a085a 0%,#350135 100%), linear-gradient(32deg, rgba(255,255,255,0.25) 33%, rgba(153,153,153,0.25) 100%)"
        />
      }
    >
      {/*TODO: photoshop below image*/}
      <SEO metaImage={ShareCard} />
      <Container maxWidth={900} noVerticalPadding>
        <Grid>
          {portfolioItems
            .filter(item => item.title === 'Pattaya Nightlife Guide')
            .map(item => (
              <BlackCard key={slugify(item.title)}>
                <div css={css({height: '100%'})}>
                  <a href={item.url}>
                    <h2>{item.title}</h2>
                  </a>
                  <p>{item.summary}</p>
                  <a className="button" href={item.url}>
                    {item.cta}{' '}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="6"
                      height="9"
                      viewBox="0 0 6 9"
                    >
                      <polyline
                        fill="none"
                        stroke="#A0C3FF"
                        strokeWidth="2"
                        points="57 18 60.5 21.5 57 25"
                        transform="translate(-56 -17)"
                      />
                    </svg>
                  </a>
                </div>
                <div css={css({width: '100%'})}>
                  {courseImageByTitle('Pattaya Nightlife Guide')}
                </div>
              </BlackCard>
            ))}
          {portfolioItems
            .filter(item => item.title === 'VenueX Mobile App')
            .map(item => (
              <WhiteCard key={slugify(item.title)}>
                <div css={css({height: '100%'})}>
                  <a href={item.url}>
                    <h2>{item.title}</h2>
                  </a>
                  <p>{item.summary}</p>
                  <a className="button" href={item.url}>
                    {item.cta}{' '}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="6"
                      height="9"
                      viewBox="0 0 6 9"
                    >
                      <polyline
                        fill="none"
                        stroke="#CF8700"
                        strokeWidth="2"
                        points="57 18 60.5 21.5 57 25"
                        transform="translate(-56 -17)"
                      />
                    </svg>
                  </a>
                </div>
                  <div css={css({width: '40%'})}>
                    {courseImageByTitle('VenueX Mobile App')}
                  </div>
              </WhiteCard>
            ))}
          {portfolioItems
            .filter(item => item.title !== 'VenueX Mobile App' &&
              item.title !== 'Pattaya Nightlife Guide')
            .map((item) => (
              <Card key={slugify(item.title)}>
                <a href={item.url}>{courseImageByTitle(item.title)}</a>
                <div css={css({width: '100%'})}>
                  <a href={item.url}>
                    <h2>{item.title}</h2>
                  </a>
                  <Markdown source={item.summary} escapeHtml={false} />
                  <a href={item.url}>
                    {item.cta}{' '}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="6"
                      height="9"
                      viewBox="0 0 6 9"
                    >
                      <polyline
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        points="57 18 60.5 21.5 57 25"
                        transform="translate(-56 -17)"
                      />
                    </svg>
                  </a>
                </div>
              </Card>
            ))}
        </Grid>
      </Container>
    </Layout>
  )
}

const Grid = styled.div({
  position: 'relative',
  zIndex: 1,
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  [bpMaxSM]: {
    gridTemplateColumns: '1fr',
  },
  gridGap: 20,
})

const cardStyles = {
  zIndex: 2,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  boxShadow: '0 2px 4px 0 rgba(0,0,0,0.06)',
  textAlign: 'center',
  borderRadius: 5,
  overflow: 'hidden',
  paddingTop: 40,
  '.button': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 20,
    lineHeight: '1',
    fontSize: 14,
    padding: '13px 20px',
    svg: {marginLeft: 5},
    marginBottom: 32,
  },
  img: {margin: 0},
  p: {
    opacity: 0.8,
  },
  'p, h2': {
    padding: '0 1rem',
  },
  'a:hover': {
    textDecoration: 'underline',
  },
}

const Card = styled.div({
  ...cardStyles,
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  textAlign: 'center',
  padding: '48px 24px',
  background: '#FFF',

  '.gatsby-image-wrapper': {width: '160px'},
})

const BlackCard = styled.div({
  ...cardStyles,
  marginTop: -40,
  background: '#0F1B35',
  '*': {color: '#FFF'},
  'p, h2': {padding: '0 56px'},
  'div > .button': {
    background: 'rgba(19, 103, 223, 0.3)',
    color: '#FFF',
    maxWidth: 100,
    margin: '0 auto',
    marginBottom: 56,
    textDecoration: 'none',
  },
  '.button:hover': {background: 'rgba(19, 103, 223, 0.8)', color: 'white'},
  'a:hover': {
    textDecoration: 'underline',
    textDecorationColor: theme.colors.white,
  },
})

const WhiteCard = styled.div({
  ...cardStyles,
  marginTop: -40,
  background: '#FFF',
  backgroundImage: 'linear-gradient(180deg, #FFFFFF 0%, #FEFAF2 76%)',
  '*': {color: '#0F1B35'},
  'p, h2': {padding: '0 56px'},
  'div > .button': {
    background: 'rgba(255, 177, 31, 0.3)',
    color: '#CF8700',
    maxWidth: 100,
    margin: '0 auto',
    marginBottom: 56,
    polyline: {transition: 'all 500ms ease'},
    textDecoration: 'none',
  },
  '.button:hover': {
    background: 'rgba(255, 177, 31, 0.8)',
    color: '#FFF',
    polyline: {stroke: '#FFF', transition: 'all 500ms ease'},
  },
  [bpMaxSM]: {
    marginTop: 0,
  },
})

export const imagesQuery = graphql`
    {
        VenueXMobileApp: file(
            relativePath: {eq: "images/portfolio/react-mmdp-image.png"}
        ) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
            }
        }


        pattayaNightlifeGuide: file(relativePath: {eq: "images/portfolio/portfolio-pattaya-nightlife-guide.png"}) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        
        mmdp: file(relativePath: {eq: "images/portfolio/react-mmdp-image.png"}) {
        childImageSharp {
            fluid {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
        
    }
`

export default [
  {
    title: 'Pattaya Nightlife Guide',
    summary:
      'A guide to all the bars in Pattaya, Thailand',
    url: '',
    // url: 'https://pattayanight.club/',
    cta: 'Visit',
    tags: ['portfolio'],
  },
  {
    title: "VenueX Mobile App",
    summary:
      'VenueX is a technology partner that gives venues a new way to exceed client expectations, stand out from the competition, all while improving their business operations.',
    url: 'https://joinvenuex.com/',
    cta: 'Visit',
    tags: ['react'],
  },
  {
    title: "MMDP",
    summary:
      'Regulates migration inside Edo State in Nigeria, ' +
      'by helping the different partners in accomplishing viable coordination of endeavours identified with the way toward diminishing' +
      ' human trafficking and unpredictable migration. It was brought up by the state government of Edo to solve the problem of human ' +
      'trafficking and reduce illegal migration. The government does this through NGOs and international investors.',
    url: '',
    cta: 'Visit',
    tags: ['react'],
  },
]
